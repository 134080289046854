<template>
  <v-layout wrap>
    <v-flex xs12 class="product-item pl-2 pr-2">
      <v-carousel
        v-model="active"
        class="relative"
        :hide-delimiter-background="true" 
        :hide-delimiters="true"
        :show-arrows="isMobile ? false : true"
        :height="isMobile ? 'auto' : 'auto'" 
        :style="isMobile ? item.thumbnail === 'BIG' ? 'min-height:530px' : 'min-height:260px' : 'min-height:100vh'"
        style="background-color:#fafafa"
        :id="'row-'+ parentIndex +'-'+ index"
      >
        <v-carousel-item v-for="(image, ind) in item.url_images" :key="ind">
          <v-row v-if="!isMobile" class="fill-height" align="center" justify="center" @click="detail(item)">
            <ImageItem :source="image" :alternate="item.product_code"></ImageItem>
          </v-row>
          
          <div v-else @click="detail(item)">
            <ImageItem :source="image" :alternate="item.product_code"></ImageItem>
          </div>
        </v-carousel-item>
        
        <ProductLabel :item="item" :is-mobile="isMobile" :sold-color="soldColor" />

        <Countdown v-if="item && item.countdown" class="countdown" :isMobile="isMobile" :date="getTime(item.end_at)" @finish="item.countdown = false"/>
      </v-carousel>
    </v-flex>

    <v-flex xs12 v-if="isMobile" class="center">
      <SliderDelimeter :url_images="item.url_images" :active="active" :pointer-color="pointerColor" />
    </v-flex>

    <v-flex xs12 class="center pointer" @click="detail(item)">
      <div>
        <p class="text"> {{ item.product_name }}</p>
        <p v-if="item.harga_coret && item.harga_coret !== '0'" class="text-through"> {{ addComma(item.harga_coret) }} IDR </p>
        <p v-if="item.start_price == '0'" class="text" style="color:#da291c">  {{ item.selling_price === 0 ? '???' : addComma(item.selling_price) + ' IDR' }} </p>
        <p v-if="item.start_price != '0'" class="text" style="color:#da291c">
          {{ addComma(item.start_price) }} IDR -  {{ addComma(item.end_price) }} IDR
        </p>
      </div>

      <ProductVariant :item="item" />
      
      <p class="text text-sold" align="center" v-if="item.sold_qty && item.sold_qty !== '0' && brand === '9'">
        Terjual {{ item.sold_qty}} pcs
      </p>

      <!-- <ProductRating :rating="item.rating_star" /> -->
      
      <!-- <v-flex xs12 class="center" v-if="item.is_bundling">
        <div class="shop-variant">
          <span>
            <i :class="item.status === 'sold' ? 'shop-variant-s' : 'shop-variant-a'"> BUNDLING </i>
          </span>
        </div>
      </v-flex> -->
    </v-flex>
  </v-layout>
</template>

<script>
import ImageItem from '@/components/ImageItem'
import Countdown from '@/components/Countdown'
import SliderDelimeter from '@/components/Shop/SliderDelimeter'
import ProductVariant from '@/components/Shop/ProductVariant'
import ProductLabel from '@/components/Shop/ProductLabel'
// import ProductRating from '@/components/Shop/ProductRating'

export default {
  name: 'ProductItem',
  components: { ImageItem, Countdown, SliderDelimeter, ProductVariant, ProductLabel },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    parentIndex: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    pointerColor: {
      type: String,
      default: '#000'
    },
    soldColor: {
      type: String,
      default: 'rgb(218,41,28)'
    }
  },
  data () {
    return {
      active: 0,
      brand: process.env.VUE_APP_BRAND
    }
  },
  methods: {
    detail (item) {
      this.$store.commit('setYIndex', ''+ this.parentIndex + '-' + this.index)
      this.toProduct(item)
    },
    getTime (value) {
      let t = value.split(/[- :]/);
      // Apply each element to the Date function
      let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
      return this.ceilValue((new Date(d)).getTime() / 1000)
    }
  }
}
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
}
.product-item {
  position: relative;
  text-align: center;
}
.relative {
  position: relative;
}
.center {
  text-align: center;
}
.pointer {
  cursor: pointer;
}
.text{
  font-size: 0.8rem;
}
.text-sold {
  color: #808080 !important;
  /* font-weight: bold; */
}
.text-through{
  font-size: 0.8rem;
  color: #000000;
  text-decoration: line-through;
}
.countdown {
  position: absolute;
  width: 80%;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.shop-badge-notify{
  background-color: rgb(218,41,28);
  color: #FFFFFF;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 2%;
}
.shop-badge-notify-m {
  background-color: rgb(218,41,28);
  color: #FFFFFF;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 3%;
  left: 5%;
}
.shop-badge-notify-soon{
  background-color: #e7a635;
  color: #FFFFFF;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 2%;
}
.shop-badge-notify-soon-m {
  background-color: #e7a635;
  color: #FFFFFF;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 3%;
  left: 5%;
}
.shop-badge-notify-onsale{
  background-color: rgb(218,41,28);
  color: #FFFFFF;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  right: 2%;
}

.shop-badge-notify-onsale-m {
  background-color: rgb(218,41,28);
  color: #FFFFFF;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 3%;
  right: 5%;
}
.shop-variant {
  font-size: 0.8rem;
}
.shop-variant-s {
  color: #d3d3d3;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: bold;
}

.shop-variant-a {
  color: #000;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: bold;
}
.v-window-x-transition-enter-active, .v-window-x-reverse-transition-enter-active, .v-window-y-transition-enter-active, .v-window-y-reverse-transition-enter-active, .v-window-x-transition-leave-active, .v-window-x-reverse-transition-leave-active, .v-window-y-transition-leave-active, .v-window-y-reverse-transition-leave-active {
  transition:ease-in 300ms !important;
}
</style>