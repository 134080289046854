<template>
  <div id="shop" ref="shop">
    <v-layout wrap class="ml-1 mr-1 mt-16" v-if="shopData.length > 0">
      <v-flex xs12 v-for="(category, i) in shopData" :key="i">
        <v-layout wrap>
          <v-flex :class="item.thumbnail === 'BIG' ? 'xs12 sm12 md12 pt-5 pb-5' : 'xs6 sm6 md6 pt-5 pb-5'" v-for="(item, index) in category.item" :key="index">
            <ProductItem 
              :item="item"
              :parent-index="i"
              :index="index"
              :is-mobile="isMobile"
              :pointer-color="pointerColor"
              :sold-color="soldColor" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="isEmpty">
      <v-flex xs12>
        <!-- <img src="@/assets/stock-kosong.jpg" width="40%" class="shop-img-empty" @click="toPage('Home')"> -->
        <div class="empty-product">
          <h1 class="ep-label">Product Not Available</h1>
        </div>
      </v-flex>
    </v-layout>
    <div class="end-section" style="width:100%; height: 10px"></div>
      <div v-if="loading" class="loading">
        <img class="loading-icon" src="@/assets/486.gif" alt="loading-icon" />
      </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import ProductItem from '@/components/Shop/ProductItem'

  export default {
    name: 'Shop',
    components: { ProductItem },
    computed: {
      ...mapState([
        'isLoading',
        'isMobile',
        'cartNumber',
        'category',
        'productType',
        'yIndex',
        'footer',
        "activeTab",
        "shopData",
        "latestShopData",
        "shopOffset",
        "shopRoute",
        "shopIsChild",
      ]),
      category () {
        return this.$route.params.id
      }
    },
    watch: {
      category (val) {
        this.$store.commit('setActivePage', val.toUpperCase())
        this.setMetaInfo(null, this.$route.params.id)
        // this.getCategory()
        this.handleGetCategory();
      },
       endOfSection(val) {
      if (val && this.shopData && this.shopData.length > 0) {
        this.handleGetCategory();
      }
    },
    },
    data () {
      return {
        brand: process.env.VUE_APP_BRAND,
        sliderProducts: process.env.VUE_APP_SLIDER_PRODUCT,
        shop: [],
        message: '',
        isEmpty: false,
        soldColor: process.env.VUE_APP_LABEL_SOLD,
        pointerColor: '',
        delimeters: null,
        endOfSection: false,
        loading: true,
      }
    },
    methods: {
      scrollIntoView () {
        let id = 'row-' + this.yIndex
      
        setTimeout(()=> {
          var element = document.getElementById(id)
          if (element) {
            element.scrollIntoView({
              behaviour: 'auto',
              block: 'center',
              inline: 'center'
            });

            this.$store.commit('setYIndex', '0-0')
          }
        }) 
      },
      getCategory(isInit = false) {
        this.loading = true;
        if (isInit) {
          this.$store.commit("setIsLoading", true);
        }

        const isChild = this.activeTab && this.activeTab.tab !== 0 ? "YES" : "NO"

        axios.defaults.headers = {
          id_brand: this.$route.params.brand,
          real_brand: this.brand,
          category: this.category,
          type: this.productType || this.$route.params.category,
          is_child: isChild,
          limit: 10,
          offset: this.shopOffset,
        };
        axios
          .get("/api/v1/pages/category")
          .then((response) => {
            if (response.data && response.data.data.length > 0) {
              let filteredData = response.data.data;

              if (
                this.shopOffset !== 0 &&
                this.shopData &&
                this.shopData.length > 0
              ) {
                let shop = this.shopData;
                filteredData.forEach((item) => {
                  if (shop[shop.length - 1].category === item.category) {
                    shop[shop.length - 1].item = shop[shop.length - 1].item.concat(item.item);
                  } else {
                    shop = shop.concat(item);
                  }
                });
                this.$store.commit("setShopData", shop);
              } else {
                this.$store.commit("setShopData", filteredData);
              }
              let newOffset = this.shopOffset + 10;
              this.isEmpty = false;
              this.$store.commit("setShopOffset", newOffset);
              this.$store.commit("setLatestShopData", response.data.data);
              this.$store.commit("setShopIsChild", isChild);
            } else {
              this.isEmpty =
                this.shopData && this.shopData.length > 0 ? false : true;
            }
          })
          .catch((error) => {
            this.shop = [];
            this.isEmpty =
              this.shopData && this.shopData.length > 0 ? false : true;
            this.message = error.response.data.api_msg;
          })
          .finally(() => {
            if (isInit) {
              this.$store.commit("setIsLoading", false);
            }
            this.loading = false;
          });
      },
      productImpressions (response) {
        window.dataLayer.push({
          'currencyCode': 'IDR',
          'event': 'impressions',
          'ecommerce': {
            'impressions': this.setImpressionData(response)
          }
        });
      },
      setImpressionData (response) {
        let position = 0
        let datas = []
        response.map(item => {
          item.item.map(data => {
            position++
            datas.push({
              'name': data.product_name,
              'id': data.product_code,
              'price': data.selling_price,
              'brand': this.brand,
              'category': data.category,
              'variant': data.product_name,
              'list': 'Product List',
              'position': position
            })
          })
        })
        return datas
      },
      handleGetCategory() {
        if (this.shopRoute !== this.$route.fullPath) {
          this.$store.commit("setShopRoute", this.$route.fullPath);
          this.$store.commit("setShopData", []);
          this.$store.commit("setShopOffset", 0);
        }

        setTimeout(() => {
          this.getCategory(this.shopOffset === 0);
        }, 500);
      },
      handleInitCategory() {
        if (this.shopRoute !== this.$route.fullPath) {
          this.$store.commit("setShopRoute", this.$route.fullPath);
          this.$store.commit("setShopData", []);
          this.$store.commit("setShopOffset", 0);

          setTimeout(() => {
            this.getCategory(this.shopOffset === 0);
          }, 500);
        } else {
          const isChild = this.activeTab && this.activeTab.tab !== 0 ? "YES" : "NO"
          if (this.shopIsChild === isChild) {
            this.scrollIntoView();
          } else {
            this.$store.commit("setShopRoute", this.$route.fullPath);
            this.$store.commit("setShopData", []);
            this.$store.commit("setShopOffset", 0);
            setTimeout(() => {
              this.getCategory(this.shopOffset === 0);
            }, 500);
          }
        }
      },
      handleScroll() {
        const box = document.querySelector(".end-section");
        const rect = box.getBoundingClientRect();

        const isInViewport =
          rect.top >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight);

        this.endOfSection = isInViewport;
      },
    },
    mounted () {
      this.handleInitCategory();
      this.setMetaInfo(null, this.$route.params.id)
      this.pointerColor = this.footer && this.footer.color.background ? this.footer.color.background : '#000'

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setActivePage', this.category.toUpperCase())
    },
    created() {
      window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  }
</script>

<style scoped>
  .shop-img-empty {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loading {
    width: 100%;
    text-align: center;
  }
  .loading-icon {
    width: 30px;
  }
  .empty-product {
    width: 100%;
    min-height: 100vh;
    position: relative;
  }
  .ep-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #c0c0c0;
    text-align: center;
    font-size: 24px;
  }
</style>
